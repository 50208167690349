/**
 * 	Team model popup
 */
let openModal = document.querySelectorAll(".open-button");
let closeModal = document.querySelectorAll(".close-button");

for (const openM of openModal) {
  openM.addEventListener("click", () => {
    let modal = openM.nextElementSibling;
    modal.showModal();
  });
}
for (const closeM of closeModal) {
  closeM.addEventListener("click", () => {
    let modal = closeM.parentNode.parentNode.parentNode;
    modal.close();
  });
}
