// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

const review = new Swiper(".the-review", {
  spaceBetween: 40,
  centeredSlides: true,
  loop: true,
  loopFillGroupWithBlank: true,
  pauseOnMouseEnter: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    dynamicBullets: true,
  },
});

var fourcolNoAnim = new Swiper(".fourcolNoAnim", {
  slidesPerView: 4,
  spaceBetween: 20,
  slidesPerGroup: 1,
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1400: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
  },
});

var crazySlider = new Swiper(".crazySlider", {
  slidesPerView: 5,
  spaceBetween: 20,
  slidesPerGroup: 1,
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1200: {
      slidesPerView: 5,
      spaceBetween: 20,
    },
    // 1400: {
    //   slidesPerView: 4,
    //   spaceBetween: 20,
    // },
  },
});

var safariNoAnim = new Swiper(".safari-noanim", {
  slidesPerView: 3,
  spaceBetween: 20,
  slidesPerGroup: 1,
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  },
});

var safari = new Swiper(".safari", {
  slidesPerView: 2,
  spaceBetween: 20,
  slidesPerGroup: 1,
  loop: true,
  loopFillGroupWithBlank: true,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  pagination: {
    el: ".swiper-pagination",
    // clickable: true,
    dynamicBullets: true,
  },
  navigation: {
    nextEl: ".safari-button-next",
    prevEl: ".safari-button-prev",
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    992: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
  },
});

var hiking = new Swiper(".hiking", {
  slidesPerView: 3,
  spaceBetween: 20,
  slidesPerGroup: 1,
  loop: true,
  loopFillGroupWithBlank: true,

  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  pagination: {
    el: ".swiper-pagination",
    // clickable: true,
    dynamicBullets: true,
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1400: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
  },
});

var homeSlider = new Swiper(".homeSlider", {
  lazy: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

var spot = new Swiper(".vacation-spot", {
  slidesPerView: 3,
  spaceBetween: 20,
  slidesPerGroup: 1,
  loop: true,
  loopFillGroupWithBlank: true,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  pagination: {
    el: ".swiper-pagination",
    // clickable: true,
    dynamicBullets: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    // 1400: {
    //   slidesPerView: 4,
    //   spaceBetween: 20,
    // },
  },
});

var postSlider = new Swiper(".post-slider", {
  slidesPerView: 3,
  spaceBetween: 20,
  slidesPerGroup: 1,
  loop: true,
  loopFillGroupWithBlank: true,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  },
});

var affiliates = new Swiper(".affiliates", {
  slidesPerView: 5,
  spaceBetween: 60,
  slidesPerGroup: 1,
  loop: true,
  loopFillGroupWithBlank: true,
  autoplay: {
    delay: 4000,
  },
  breakpoints: {
    0: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1200: {
      slidesPerView: 5,
      spaceBetween: 20,
    },
  },
});

// I do not trust this slider
var thumbsGallery = new Swiper(".thumbsGallery", {
  direction: "vertical",
  slidesPerView: 5,
  spaceBetween: 15,
  freeMode: true,
  watchSlidesProgress: true,
  breakpoints: {
    0: {
      direction: "horizontal",
    },
    1200: {
      direction: "vertical",
    },
  },
});
var thumbsGalleryTwo = new Swiper("#thumbsGalleryTwo", {
  lazy: true,
  spaceBetween: 15,
  navigation: {
    nextEl: ".thumbsGalleryTwo--swiper-button-next",
    prevEl: ".thumbsGalleryTwo--swiper-button-prev",
  },
  thumbs: {
    swiper: thumbsGallery,
  },
});
