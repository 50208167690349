// /* begin begin Back to Top button  */
// (function () {
//   "use strict";

//   function trackScroll() {
//     var scrolled = window.scrollY;
//     var coords = document.documentElement.clientHeight;

//     if (scrolled > coords) {
//       goTopBtn.classList.add("d-grid");
//     }
//     if (scrolled < coords) {
//       goTopBtn.classList.remove("d-grid");
//     }
//   }

//   function backToTop() {
//     if (window.scrollY > 0) {
//       window.scrollBy(0, -50);
//       setTimeout(backToTop, 0);
//     }
//   }

//   var goTopBtn = document.querySelector(".back_to_top");

//   window.addEventListener("scroll", trackScroll);
//   goTopBtn.addEventListener("click", backToTop);
// })();
// /* end begin Back to Top button  */

// Improved Back to Top button functionality using ES6 syntax

// Wrap the entire code inside a function to run when the button is present
function setupBackToTopButton() {
  "use strict";

  // Function to track the scroll position and toggle the button's visibility
  function trackScroll() {
    const scrolled = window.scrollY;
    const coords = document.documentElement.clientHeight;

    if (scrolled > coords) {
      goTopBtn.classList.add("d-grid");
    } else {
      goTopBtn.classList.remove("d-grid");
    }
  }

  // Function to scroll back to the top of the page smoothly
  function backToTop() {
    if (window.scrollY > 0) {
      window.scrollBy(0, -60);
      // Request the next frame for smooth scrolling effect
      requestAnimationFrame(backToTop);
    }
  }

  // Find the back-to-top button in the DOM using querySelector
  const goTopBtn = document.querySelector(".back_to_top");

  if (goTopBtn) {
    // Add scroll event listener to track scrolling
    window.addEventListener("scroll", trackScroll);

    // Add click event listener to the button to scroll back to top
    goTopBtn.addEventListener("click", backToTop);
  }
}

// Check if the DOM content has loaded
document.addEventListener("DOMContentLoaded", () => {
  // Call the setup function to initialize the Back to Top button functionality
  setupBackToTopButton();
});
